<template>
  <div>
    <LoginPage v-if="!isAuthenticated" @login-success="isAuthenticated = true"></LoginPage>
    <div v-else class="process-container">
      <button @click="startProcess" :disabled="stage !== 0">Начать процесс</button>
      <h1>Продукты</h1>
      <div v-for="(step, index) in steps" :key="index" :class="{'step': true, 'completed': stage > index, 'active': stage === index}">
        <p>{{ index + 1 }}. {{ step.message }}</p>
        <span v-if="stage > index" class="checkmark">&#10003;</span>
      </div>
      <div v-if="stage === 'done'" class="done">Процесс успешно завершен.</div>
      <div v-if="stage === 'error'" class="error">Произошла ошибка во время выполнения процесса.</div>

      <div v-if="fileContents.notFoundSkus.length > 0" class="file-content">
        <h2>Не найденные SKU</h2>
        <table>
          <thead>
          <tr>
            <th>SKU</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="sku in fileContents.notFoundSkus" :key="sku">
            <td>{{ sku }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div v-if="fileContents.multipleItemsSkus.length > 0" class="file-content">
        <h2>SKU с несколькими элементами</h2>
        <table>
          <thead>
          <tr>
            <th>SKU</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="sku in fileContents.multipleItemsSkus" :key="sku">
            <td>{{ sku }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div v-if="fileContents.updatedProducts.length > 0" class="file-content">
        <h2>Обновленные продукты</h2>
        <table>
          <thead>
          <tr>
            <th>ID продукта</th>
            <th>SKU</th>
            <th>Старая цена</th>
            <th>Новая цена</th>
            <th>Доступность</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="product in fileContents.updatedProducts" :key="product.product_id">
            <td>{{ product.product_id }}</td>
            <td>{{ product.sku }}</td>
            <td>{{ product.price }}</td>
            <td>{{ product.new_price }}</td>
            <td>{{ product.availability }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import LoginPage from './LoginPage.vue';

export default {
  name: 'ProductProcess',
  components: {
    LoginPage
  },
  setup() {
    const isAuthenticated = ref(false);
    const brandId = '77'; // Значение по умолчанию
    const products = ref([]);
    const stage = ref(0);
    const steps = ref([
      {message: 'Подключение к серверу...'},
      {message: 'Получение данных с сервера...'},
      {message: 'Данные получены и сохранены в JSON файл успешно.'},
      {message: 'Сравнение с внешним API и обновление данных...'},
      {message: 'Данные обновлены и сохранены в новый JSON файл успешно.'},
      {message: 'SKU не найдены в API сохранены в отдельный файл.'},
      {message: 'SKU с несколькими элементами сохранены в отдельный файл.'},
      {message: 'Обновление базы данных новыми ценами и наличием...'}
    ]);
    const fileContents = ref({
      notFoundSkus: [],
      multipleItemsSkus: [],
      updatedProducts: []
    });

    const startProcess = async () => {
      try {
        stage.value = 1;
        steps.value[0].message = 'Подключение к серверу...';

        const fetchResponse = await axios.get(`/api/products/${brandId}`);
        products.value = fetchResponse.data.data || [];
        stage.value = 2;
        steps.value[1].message = 'Получение данных с сервера...';

        stage.value = 3;
        steps.value[2].message = 'Данные получены и сохранены в JSON файл успешно.';

        const updateResponse = await axios.get(`/api/update-products/${brandId}`);
        products.value = updateResponse.data.data || [];
        fileContents.value.notFoundSkus = updateResponse.data.notFoundSkus || [];
        fileContents.value.multipleItemsSkus = updateResponse.data.multipleItemsSkus || [];
        fileContents.value.updatedProducts = updateResponse.data.data || [];
        stage.value = 4;
        steps.value[3].message = 'Сравнение с внешним API и обновление данных...';

        stage.value = 5;
        steps.value[4].message = 'Данные обновлены и сохранены в новый JSON файл успешно.';

        stage.value = 6;
        if (updateResponse.data.notFoundSkus.length > 0) {
          steps.value[5].message = 'SKU не найдены в API сохранены в отдельный файл.';
        }
        if (updateResponse.data.multipleItemsSkus.length > 0) {
          steps.value[6].message = 'SKU с несколькими элементами сохранены в отдельный файл.';
        }

        stage.value = 7;
        steps.value[7].message = 'Обновление базы данных новыми ценами и наличием...';

        await axios.post('/api/update-database');

        stage.value = 'done';
      } catch (error) {
        console.error('Error in process:', error);
        stage.value = 'error';
      }
    };

    return {
      isAuthenticated,
      products,
      stage,
      steps,
      fileContents,
      startProcess,
    };
  },
};
</script>

<style scoped>
.process-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  position: relative;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
}

button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.step {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
}

.completed {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.active {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}

.checkmark {
  font-size: 1.5em;
  color: #28a745;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 10px;
  margin: 5px 0;
  border-radius: 4px;
}

.done {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 10px;
  margin: 5px 0;
  border-radius: 4px;
}

.file-content {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ccc;
}

th, td {
  padding: 8px;
  text-align: left;
}
</style>
