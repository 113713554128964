<template>
  <div id="app">
    <ProductProcess />
  </div>
</template>

<script>
import ProductProcess from './components/ProductProcess.vue';

export default {
  name: 'App',
  components: {
    ProductProcess,
  },
};
</script>

<style>
/* Добавьте стили по вашему усмотрению */
</style>
